import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ABeagleContext from '../../contexts/abeagle';
import BuzzContext from '../../contexts/buzz';
import StickyContext from '../../contexts/sticky';
import CommentsContext from '../../contexts/comments';
import { scrollY } from '../../utils/scroll';
import defaultStyles from './styles/default.module.scss';
import primeDayStyles from './styles/primeday.module.scss';
import shoppyBotStyles from './styles/shoppyBot.module.scss';
import { XIcon } from '@buzzfeed/react-components';
import storage from '@buzzfeed/bf-utils/lib/storage';
import debounce from 'just-debounce-it';
import { trackClientContentAction } from '../../hooks/analytics/client-event-tracking';

// Promotion components
import PromoNewsletterSignup from './promos/PromoNewsletterSignup';
import PromoShoppyBotBanner from './promos/PromoShoppyBotBanner';

let FocusTrap;
if (typeof window !== 'undefined') {
  FocusTrap = require('focus-trap-react');
}

// Use today's date to determine whether cookie may have expired
const TODAY = new Date();

const PromoPopup = ({ dismissed }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDismissed, setIsDismissed] = useState(dismissed || false);
  const [hasExitIntent, setExitIntent] = useState(false);
  const [shoppyExperimentValue, setShoppyExperimentValue] = useState(false);
  const cleanupExitHandlers = useRef(() => {});
  const buzz = useContext(BuzzContext);
  const promoPopup = useRef(null);
  const { isCommentsPanelOpen } = useContext(CommentsContext);
  const { notify: notifyStickyContext } = useContext(StickyContext);
  const { experiments, getFeatureFlagValue, getExperimentValue } = useContext(ABeagleContext);

  // possible promo components of the form "'feature flag': component"
  // in order of precedence
  const components = {
    'shoppybot-popup': PromoShoppyBotBanner,
    'newsletter-primeday': PromoNewsletterSignup,
    'newsletter-popup': PromoNewsletterSignup,
  };

  const styles = {
    'shoppybot-popup': shoppyBotStyles,
    'newsletter-primeday': primeDayStyles,
    'newsletter-popup': defaultStyles,
  };

  const storageOptions = {
    'shoppybot-popup': { set: storage.sessionSet, get: storage.sessionGet },
    'newsletter-primeday': { set: storage.sessionSet, get: storage.sessionGet },
    'newsletter-popup': { set: storage.sessionSet, get: storage.sessionGet },
  };

  useEffect(() => {
    if (experiments && experiments.loaded) {
      setShoppyExperimentValue(getExperimentValue('shoppy-bpage-test', {
        rejectErrors: false,
      }))
    }
  }, [experiments, getExperimentValue]);

  const promos = Object.keys(components);
  // get experiment variants and determine current promotion
  const experimentsLoaded = !experiments.stale && experiments.loaded;
  const currentPromo = experimentsLoaded
    ? shoppyExperimentValue === 'banner'
      ? 'shoppybot-popup' : promos.find((promo) => getFeatureFlagValue(promo))
    : null;
    
  // SPA
  useEffect(() => {
    cleanupExitHandlers.current();
    setIsVisible(false);
    setIsDismissed(dismissed || false);
    setExitIntent(false);
  }, [buzz.id, dismissed]);

  // Marks as dismissed when there's no eligible promotion
  // or the popup has been dismissed by user within last 7 days
  useEffect(() => {
    if (!experimentsLoaded) {
      return;
    }
    if (!currentPromo) {
      setIsDismissed(true);
      return;
    }
    // check whether promo has already been dismissed
    let dismissDate = storageOptions[currentPromo].get(`popup_${currentPromo}`);

    if (dismissDate) {
      dismissDate = new Date(dismissDate);
      let expirationDate = new Date(
        dismissDate.getFullYear(),
        dismissDate.getMonth(),
        dismissDate.getDate() + 7
      );
      setIsDismissed(expirationDate >= TODAY);
    }
  }, [experimentsLoaded, currentPromo]);

  // Watches for exit behavior (scroll up or leave window)
  const attachExitHandlers = useCallback(() => {
    cleanupExitHandlers.current();
    const exitBehavior = () => {
      setExitIntent(true);
      cleanup();
    };
    let savedOffset = 0;
    const onScroll = debounce(() => {
      const scrolled = scrollY();
      if (scrolled + 10 < savedOffset) {
        exitBehavior();
      }
      savedOffset = scrolled;
    }, 100);
    const onLeavePage = () => exitBehavior();
    window.addEventListener('scroll', onScroll);
    window.addEventListener('blur', onLeavePage);
    function cleanup() {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('blur', onLeavePage);
    }
    cleanupExitHandlers.current = cleanup;
  }, []);

  // Reacts to the sticky manager commands
  const notifyStickyManager = useCallback(() => {
    const handler = ({ shouldStick }) => {
      if (isDismissed) {
        return false;
      }

      if (shouldStick) {
        if (!hasExitIntent) {
          attachExitHandlers();
          return false; // do not make visible yet, waiting for exit behavior
        } else {
          setIsVisible(true);
          return true;
        }
      } else {
        setIsVisible(false);
        return false;
      }
    };
    notifyStickyContext('bottom', {
      canStick: !isDismissed,
      callback: handler,
    });
  }, [isDismissed, hasExitIntent, notifyStickyContext, attachExitHandlers]);

  // Notifies the sticky manager once an eligible promotion (if any) is known
  useEffect(() => {
    if (experimentsLoaded) {
      notifyStickyManager();
    }
  }, [experimentsLoaded, notifyStickyManager]);

  // Notifies the sticky manager once there's an exit intent
  useEffect(() => {
    if (hasExitIntent) {
      notifyStickyManager();
    }
  }, [hasExitIntent, notifyStickyManager]);

  // The sole purpose of this effect is to perform cleanup on umnount
  useEffect(() => {
    return cleanupExitHandlers.current;
  }, []);

  // don't render if experiments haven't loaded
  if (!experimentsLoaded || !currentPromo) {
    return null;
  }

  // enable dismiss popup
  const onDismiss = (forceClose = false) => {
    // check whether promo has already been subscribed - dismissDate > TODAY
    const dismissDate =
      new Date(storageOptions[currentPromo].get(`popup_${currentPromo}`)) ||
      TODAY;
    if (dismissDate <= TODAY) {
      storageOptions[currentPromo].set({
        key: `popup_${currentPromo}`,
        value: TODAY.toISOString(),
      });
    }
    if (forceClose) {
      setIsDismissed(true);
    }

    if (currentPromo === 'shoppybot-popup') {
      trackClientContentAction(buzz, {
        unit_type: 'buzz_bottom',
        unit_name: buzz.id,
        subunit_type: 'component',
        subunit_name: 'shoppy_bot_banner',
        item_type: 'button',
        item_name: 'close',
        position_in_unit: 0,
        position_in_subunit: 1,
        action_type: 'select',
        action_value: 'close',
      });
    }
  };

  const Promotion = components[currentPromo];
  const style = styles[currentPromo];
  const customStyle = `promoPopup--${currentPromo}`;

  if (isCommentsPanelOpen) {
    return null;
  }

  return (
    <FocusTrap
      focusTrapOptions={{
        initialFocus: '#promo-popup-close-button',
        allowOutsideClick: true,
        preventScroll: true,
        clickOutsideDeactivates: true,
      }}
    >
      <section
        id="promo-popup"
        aria-label="Promotional Popup"
        aria-modal="true"
        role="dialog"
        ref={promoPopup}
        className={`${style.promoPopup} ${style[customStyle]}
          ${isVisible && !isDismissed ? style.visible : ''}
        `}
      >
        <div className={style.content} role="group">
          <Promotion dismissPromo={onDismiss} currentPromo={currentPromo} />
        </div>
        <button
          id="promo-popup-close-button"
          className={style.closeButton}
          type="button"
          aria-label="Close"
          onClick={() => onDismiss(true)}
        >
          <XIcon className={style.xIcon} aria-hidden={true} title="Close" />
        </button>
      </section>
    </FocusTrap>
  );
};

export default PromoPopup;
