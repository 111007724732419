const headerBrand = section =>
  ({
    asis: 'asis',
    'as/is': 'asis',
    bringme: 'bringme',
    travel: 'bringme',
    goodful: 'goodful',
    news: 'news',
    nifty: 'nifty',
    parents: 'parents',
  }[section]);

module.exports = {
  headerBrand
};
