import { ErrorBoundary, useBreakpoint } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import DestinationContext from '../../../contexts/destination';
import CommentsWrapper from '../../CommentsWrapper';
import AdUnit from '../../Ads/AdUnit';
import ContentBottom from '../../ContentBottom';
import FooterCommunity from '../../FooterCommunity';
import Headline from '../../Headline';
import { BottomRecircList, RelatedRecircList } from '../../RecircList/variants';
import { isEligible } from '../../RecircList/variants/RelatedRecircList/utils';
import Sidebar from '../../Sidebar';
import Package from '../../Package/lazy';
import layoutStyles from '../post.module.scss';
import { isNews } from '../../../utils/isNews';

function CommentsPost({
  buzz,
  badges,
}) {
  const ref = useRef(null);
  const { destination } = useContext(DestinationContext);
  const isMobile = useBreakpoint('(max-width:500px)'); // page level break is based on bf_header_ui break
  const [commentCount, setCommentCount] = useState(null);

  const isEligibleRelatedRecirc = isEligible(buzz);

  return (
    <main
      id="buzz-content"
      className={`${layoutStyles.main} ${layoutStyles[destination]} ${layoutStyles.commentsMain} embed-content`}
      ref={ref}
    >
      <div
        className={`${layoutStyles.article} ${layoutStyles.default} ${layoutStyles[destination]} embed-post`}
      >
        <div className={layoutStyles.content}>
          <article>
            <Headline buzz={buzz} badges={badges} />
          </article>

          <ErrorBoundary onError={captureException}>
            <div className={layoutStyles.commentsWrapper} >
              <CommentsWrapper
                buzz={buzz}
                commentCount={commentCount}
                onCommentsLoaded={setCommentCount}
                firstPage={10}
                repliesFirstPage={2}
              />
            </div>
            {isEligibleRelatedRecirc && <AdUnit
              type="promo-inline1"
              pixiedust={{
                unit_type: 'buzz_bottom',
                unit_name: buzz.id,
                position_in_unit: 0,
              }}
            />}
            <ContentBottom buzz={buzz} />
            {
              destination === 'buzzfeed' &&
              !buzz.isShopping &&
              !isNews(buzz) &&
              <>
                <Package
                  style={{ marginBottom: '3rem' }}
                  isNewDesign={true}
                />
                <AdUnit
                  type="story-bpage-desktop"
                  pixiedust={{
                    unit_type: 'buzz_bottom',
                    unit_name: buzz.id,
                    position_in_unit: 1,
                  }}
                  style={{ marginTop: '-2rem' }}
                />
              </>
            }
            <RelatedRecircList />
            {
              destination === 'buzzfeed' &&
              buzz.isShopping &&
              !isNews(buzz) &&
              <>
                <AdUnit
                  type="story-bpage-desktop"
                  pixiedust={{
                    unit_type: 'buzz_bottom',
                    unit_name: buzz.id,
                    position_in_unit: 1,
                  }}
                />
                <Package
                  style={{ marginTop: isMobile ? '1rem' : '-2rem' }}
                  isNewDesign={true}
                />
              </>
            }
            <div className={layoutStyles.communityFooterWrapper}>
              <FooterCommunity />
            </div>
            {isMobile && (
              <AdUnit
                type="bigstory"
                pixiedust={{
                  unit_type: 'buzz_bottom',
                  unit_name: buzz.id,
                  position_in_unit: 1,
                }}
              />
            )}
          </ErrorBoundary>
        </div>
        <Sidebar
          pixiedust={{
            unit_type: 'sidebar',
            unit_name: 'right',
            position_in_unit: 0,
          }}
        />
      </div>
      <ErrorBoundary onError={captureException}>
        <BottomRecircList />
      </ErrorBoundary>
    </main>
  );
}

CommentsPost.propTypes = {
  buzz: PropTypes.object.isRequired,
  badges: PropTypes.array,
};

export default CommentsPost;
