
import { useEffect, useRef } from 'react';
import { useConsent } from '@buzzfeed/react-components';

import { attachClientInternalLinkHandler, attachClientExternalLinkHandler } from './client-event-tracking';

export function useClickTracking(unitData, external = false) {
  useConsent('tracking');
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return () => {};
    }

    const detachUnitTracking = external
      ? attachClientExternalLinkHandler(element, {}, unitData)
      : attachClientInternalLinkHandler(element, {}, unitData);

    return () => {
      detachUnitTracking();
    };
  }, [unitData, external]);

  return ref;
}
