import React, { useContext, useCallback, useMemo } from 'react';
import BuzzContext from '../../../../contexts/buzz';
import DestinationContext from '../../../../contexts/destination';
import {
  NewsletterForm,
  NewsletterSignupContainer,
  NewsletterTitle,
} from '../../../NewsletterSignup/components';
import promotions from './promotions';
import { trackClientContentAction } from '../../../../hooks/analytics/client-event-tracking';
import NewsletterSignupShopping from '../../../NewsletterSignup/variants/NewsletterSignupShopping';

function PromoNewsletterSignup({ dismissPromo, currentPromo }) {
  const buzz = useContext(BuzzContext);
  const { destination } = useContext(DestinationContext);

  const hasPetsTag = useMemo(() => buzz.tags.includes('amazon-pets'), [buzz]);
  const trackSignupSuccess = useCallback(
    newsletterName => {
      trackClientContentAction(buzz, {
        action_type: 'signup',
        action_value: newsletterName,
        item_name: 'email',
        item_type: 'submission',
        unit_name: buzz.id,
        unit_type: 'buzz_bottom',
        position_in_subunit: 0,
        position_in_unit: 0,
        subunit_name: 'newsletter_signup',
        subunit_type: 'component',
      });
    },
    [buzz, destination]
  );

  if (
    buzz.tags.includes('--no-newsletter-signup') ||
    buzz.bfpFormats.includes('newsletter_signup') ||
    buzz.flags.nsfw ||
    buzz.isAd ||
    !buzz.isUS
  ) {
    return null;
  }

  if (hasPetsTag) {
    currentPromo = 'amazonpets';
  }

  return (
    <NewsletterSignup
      trackSignupSuccess={trackSignupSuccess}
      promotion={currentPromo}
      dismissPromo={dismissPromo}
    />
  );
}

function NewsletterSignup({
  trackSignupSuccess,
  promotion,
  dismissPromo,
}) {
  const { styles, category, title, subtitle, useShoppingDefaultTamplate = false, newsletterId } = promotions[promotion];
  const onSubmitSuccess = useCallback(() => {
    trackSignupSuccess(category);
    dismissPromo();
  }, [trackSignupSuccess, category, dismissPromo]);

  return (
    <div className={styles.wrapper}>
      {useShoppingDefaultTamplate &&
        <NewsletterSignupShopping
          trackSignupSuccess={onSubmitSuccess}
          title={title}
          description={subtitle}
          promotion={promotion}
        />
      }
      {!useShoppingDefaultTamplate &&
        <NewsletterSignupContainer className={styles.container}>
          <div className={styles.copy}>
            <NewsletterTitle>{title}</NewsletterTitle>
            <p>{subtitle}</p>
          </div>
          <NewsletterForm
            category={category}
            className={styles.form}
            onSubmitSuccess={onSubmitSuccess}
            newsletterId={newsletterId}
            promotion={promotion}
          />
        </NewsletterSignupContainer>
      }
    </div>
  );
}

export default PromoNewsletterSignup;
