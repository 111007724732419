import { useCallback } from 'react';
import {
  trackClientContentAction,
  trackClientInternalLink,
  trackClientExternalLink
} from './client-event-tracking';

/**
 * Returns function for tracking events
 *
 * Import at the top of the component/file as:
 * const trackingFn = useNewsBreakingTickerTracking(buzz)
 *
 * Pass the tracking fn to the comments component via react props
 * eventData will be provided by the component when it fires the event
 *
 * @returns {Function}
 */
export function useNewsBreakingTickerTracking(buzz) {
  const commonData = {
    unit_type: 'nav',
    unit_name: 'breaking-ticker'
  };

  return useCallback(
    data => {
      if (data.type === 'web_internal_link') {
        trackClientInternalLink(buzz, {
          ...commonData,
          ...data
        });
      } else if (data.type === 'web_external_link') {
        trackClientExternalLink(buzz, {
          ...commonData,
          ...data
        });
      } else {
        trackClientContentAction(buzz, {
          ...commonData,
          ...data
        });
      }
    },
    [buzz]
  );
}
