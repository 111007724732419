import React from 'react';
import { ShoppingNav } from '@buzzfeed/react-components';
import {
  trackClientContentAction,
  attachClientImpressionHandler,
} from '../../hooks/analytics/client-event-tracking';
import { useUnitTracking } from '../../hooks/analytics/useShoppingUnitTracking';
import { useClickTracking } from '../../hooks/analytics/useShoppingClickTracking';

export function ShoppingHeader({ setShowOverlay, showOverlay, buzz }) {
  const edition = 'en-us';

  const tracking = {
    destination: 'buzzfeed',
    context_page_id: buzz.id,
    context_page_type: buzz.isExplicitCommentsPage ? 'comments' : 'buzz',
    page_edition: edition,
  };

  return (
    <ShoppingNav
      edition={edition}
      tracking={tracking}
      setShowOverlay={setShowOverlay}
      showOverlay={showOverlay}
      pageType="mini"
      trackClientContentAction={trackClientContentAction}
      attachClientImpressionHandler={attachClientImpressionHandler}
      useUnitTracking={useUnitTracking}
      useClickTracking={useClickTracking}
    />
  );
}
