import { useContext } from 'react';
import AdUnit from '../../AdUnit';
import CommentsContext from '../../../../contexts/comments';
import {
  config as adsConfig
} from '@buzzfeed/adlib/dist/module/bindings/react';
import styles from './toolbar-ad.module.scss';

/**
 * This is the toolbar Ad unit
 * It will render big toolbar with 728x90 when test is on
 * render the regular small toolbar for the rest
 */
function ToolbarAdUnit() {
  const { isCommentsPanelOpen } = useContext(CommentsContext);
  const adConfig = { ...adsConfig['toolbar'] };

  return (
    <AdUnit
      type="toolbar"
      config={adConfig}
      className={isCommentsPanelOpen ? styles.toolbarAdComments : ''}
    />
  );
}
export default ToolbarAdUnit;
