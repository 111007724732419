import React from 'react';

export const BuzzfeedLogo = ({ className, title='BuzzFeed' }) => (
  <svg className={className} width="72" height="13" viewBox="0 0 72 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{title}</title>
    <g clipPath="url(#clip0_1398_642)">
      <path d="M7.67701 6.66786C8.22996 6.71063 8.74612 6.96145 9.12144 7.36976C9.33434 7.6076 9.49816 7.88515 9.60351 8.18647C9.70886 8.48779 9.75365 8.80696 9.73532 9.12564C9.7543 9.53405 9.68328 9.94164 9.52728 10.3196C9.37127 10.6975 9.1341 11.0365 8.83255 11.3126C8.23071 11.8542 7.35879 12.1251 6.21679 12.1251H0.655762V1.43408H6.19422C7.17071 1.43408 7.95537 1.69965 8.54818 2.23077C8.84177 2.4987 9.07311 2.82768 9.22596 3.19458C9.3788 3.56148 9.44943 3.95741 9.43289 4.35453C9.46305 4.93926 9.26201 5.5124 8.87318 5.95016C8.56819 6.30762 8.1425 6.54054 7.67701 6.60467V6.66786ZM3.28958 5.85311H5.58711C5.93146 5.87065 6.26907 5.75326 6.52825 5.52586C6.64578 5.41473 6.73805 5.27962 6.79879 5.1297C6.85954 4.97978 6.88734 4.81856 6.88032 4.65695C6.88554 4.50673 6.85749 4.35722 6.79818 4.2191C6.73888 4.08098 6.64978 3.95768 6.53727 3.858C6.30218 3.65616 6.00071 3.54844 5.69093 3.55558H3.28958V5.85311ZM5.74735 10.0036C6.098 10.0258 6.44356 9.9109 6.71106 9.68309C6.82652 9.56933 6.91612 9.43203 6.97376 9.28053C7.0314 9.12903 7.05572 8.9669 7.04508 8.80516C7.05233 8.6484 7.02472 8.49199 6.96423 8.3472C6.90373 8.20241 6.81187 8.07284 6.69526 7.96784C6.44374 7.75502 6.12174 7.64393 5.79249 7.65639H3.28958V10.0036H5.74735Z" fill="#3D3C3C"/>
      <path d="M18.8781 12.1256H16.3255V11.3605C16.0099 11.6919 15.6277 11.9526 15.2041 12.1257C14.7805 12.2988 14.3251 12.3803 13.8677 12.3649C13.4478 12.3827 13.0291 12.3101 12.6397 12.152C12.2503 11.9939 11.8994 11.754 11.6108 11.4485C11.0406 10.8362 10.7554 10.0297 10.7554 9.02914V4.11133H13.2945V8.48748C13.2756 8.90227 13.4116 9.30926 13.6759 9.62948C13.7978 9.77183 13.9501 9.88504 14.1216 9.96081C14.293 10.0366 14.4793 10.073 14.6667 10.0673C14.8983 10.0798 15.1296 10.0375 15.3417 9.94378C15.5539 9.85005 15.7409 9.70756 15.8876 9.52792C16.1788 9.16004 16.3255 8.60033 16.3255 7.84426V4.11133H18.8781V12.1256Z" fill="#3D3C3C"/>
      <path d="M26.7412 12.1256H19.9818V11.6449L23.1595 6.14255H20.0607V4.11133H26.7006V4.59205L23.5206 10.0989H26.7367L26.7412 12.1256Z" fill="#3D3C3C"/>
      <path d="M34.6042 12.1256H27.8335V11.6449L31.0225 6.14255H27.9238V4.11133H34.5636V4.59205L31.3859 10.0989H34.6042V12.1256Z" fill="#3D3C3C"/>
      <path d="M38.4004 5.99756H43.018V8.29509H38.4004V12.1318H35.7688V1.43408H43.4423V3.74742H38.4004V5.99756Z" fill="#3D3C3C"/>
      <path d="M61.7256 8.77398H55.8576C56.0088 9.76928 56.7603 10.3222 57.8753 10.3222C58.7499 10.3065 59.596 10.0089 60.2879 9.47362L61.2674 11.1708C60.3202 11.9646 59.1176 12.3887 57.882 12.3647C55.1963 12.3647 53.3682 10.7285 53.3682 8.11947C53.3494 7.55412 53.449 6.99109 53.6604 6.46643C53.8718 5.94176 54.1905 5.46703 54.596 5.07264C54.9921 4.68425 55.4619 4.37911 55.9779 4.17522C56.4938 3.97134 57.0453 3.87283 57.5999 3.8855C58.1401 3.87211 58.6775 3.96841 59.1795 4.16857C59.6814 4.36874 60.1376 4.66861 60.5204 5.05007C61.3073 5.82645 61.7113 6.85259 61.7323 8.1285L61.7256 8.77398ZM56.555 6.29137C56.2364 6.51491 56.0051 6.84189 55.9005 7.21671H59.2046C59.114 6.84355 58.9001 6.51192 58.5975 6.27558C58.3093 6.06129 57.959 5.94715 57.5999 5.95058C57.2251 5.94887 56.8593 6.06579 56.555 6.2846V6.29137Z" fill="#3D3C3C"/>
      <path d="M52.3887 8.77398H46.5208C46.6697 9.76928 47.4235 10.3222 48.5362 10.3222C49.4113 10.3057 50.258 10.0082 50.9511 9.47362L51.9306 11.1708C50.9831 11.964 49.7807 12.3881 48.5452 12.3647C45.8595 12.3647 44.0314 10.7285 44.0314 8.11947C44.0129 7.55396 44.1128 6.99082 44.3246 6.46615C44.5364 5.94148 44.8555 5.46684 45.2614 5.07264C45.656 4.68542 46.124 4.38094 46.638 4.17708C47.1519 3.97322 47.7013 3.87409 48.2541 3.8855C48.7943 3.87211 49.3317 3.96841 49.8336 4.16857C50.3356 4.36874 50.7917 4.66861 51.1745 5.05007C51.9629 5.82645 52.3677 6.85259 52.3887 8.1285V8.77398ZM47.2182 6.2846C46.8991 6.5076 46.6676 6.83479 46.5637 7.20994H49.8655C49.7757 6.83702 49.5626 6.50538 49.2607 6.2688C48.969 6.0555 48.6154 5.94371 48.2541 5.95058C47.8822 5.95 47.5197 6.0669 47.2182 6.2846V6.2846Z" fill="#3D3C3C"/>
      <path d="M71.6041 12.1252H69.0515V11.4865C68.3663 12.0852 67.4781 12.3992 66.5689 12.3644C66.0496 12.3724 65.5345 12.2705 65.0572 12.0655C64.58 11.8606 64.1515 11.5571 63.7997 11.175C63.0383 10.3836 62.6584 9.36499 62.6599 8.11917C62.6398 7.56127 62.7301 7.00488 62.9257 6.482C63.1213 5.95911 63.4183 5.48005 63.7997 5.07234C64.1506 4.69108 64.578 4.38824 65.0541 4.18366C65.5301 3.97908 66.044 3.87738 66.5621 3.88521C67.4709 3.85069 68.3587 4.16384 69.0447 4.76089V0.635254H71.5973L71.6041 12.1252ZM68.4692 9.61325C68.6626 9.41676 68.8139 9.18296 68.914 8.9261C69.0141 8.66924 69.0609 8.39469 69.0515 8.11917C69.0594 7.84553 69.0119 7.57312 68.9118 7.31831C68.8117 7.0635 68.6612 6.83155 68.4692 6.63638C68.2913 6.44659 68.0758 6.29598 67.8364 6.19416C67.597 6.09235 67.3391 6.04157 67.079 6.04507C66.8193 6.03657 66.5609 6.08419 66.3214 6.18468C66.0819 6.28517 65.8668 6.43616 65.691 6.62735C65.3227 7.03495 65.1284 7.57029 65.1493 8.11917C65.1252 8.67125 65.3199 9.21055 65.691 9.62002C65.8674 9.81057 66.0825 9.96127 66.3219 10.0621C66.5612 10.1629 66.8193 10.2114 67.079 10.2046C67.3389 10.2071 67.5966 10.1559 67.8359 10.0541C68.0751 9.95237 68.2907 9.80228 68.4692 9.61325V9.61325Z" fill="#3D3C3C"/>
    </g>
    <defs>
      <clipPath id="clip0_1398_642">
        <rect width="71.2597" height="12" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>
);
