import { useEffect, useContext } from 'react';
import { trackClientPageview } from './client-event-tracking';
import DestinationContext from '../../contexts/destination';

// A reduced set of pageview tracking for the comments only version of bpages
export function usePageviewCommentsTracking(buzz) {
  const { destination } = useContext(DestinationContext);

  useEffect(() => {
    trackClientPageview(buzz);
  }, [buzz, destination]);
}
