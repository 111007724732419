import React, { useEffect, useRef, useState, useCallback } from 'react';
import { api } from '@buzzfeed/consent';
import { useNewsFooterTracking } from '../../hooks/analytics/useNewsFooterTracking';
import { sectionsItems, brandItems, followItems, companyItems } from '../../constants/news-footer';
import { BuzzfeedNewsLogo } from '../Svgs/BuzzfeedNewsLogo';
import { BuzzfeedLogo } from '../Svgs/BuzzfeedLogo';
import styles from './newspagefooter.module.scss';

const NewsPageFooter = ({ buzz }) => {
  const newsFooterRef = useRef(null);
  const trackFn = useNewsFooterTracking(buzz);
  const [showCcpa, setShowCcpa] = useState(false);

  const createTrackingData = ({ item_name, subunit_name, position_in_unit, target_content_url = '' }, position_in_subunit) => {
    let trackingData = {
      item_name,
      subunit_name,
      position_in_unit,
      position_in_subunit
    };
    if (target_content_url) {
      trackingData.target_content_url = target_content_url;
    } else {
      trackingData.target_content_type = 'feed';
    }
    return trackingData;
  };

  useEffect(() => {
    if (!newsFooterRef && !newsFooterRef.current && !trackFn) {
      return;
    }

    newsFooterRef.current.querySelector('.js-list-sections').querySelectorAll('a').forEach((link, index) => {
      const trackingData = createTrackingData(sectionsItems[index], index);
      trackFn.impressionUnit({
        element: link,
        trackingData
      });
      trackFn.clickUnit({
        element: link,
        trackingData
      });
    });

    newsFooterRef.current.querySelector('.js-list-socials').querySelectorAll('a').forEach((link, index) => {
      const trackingData = createTrackingData(followItems[index], index);
      trackFn.clickUnit({
        element: link,
        trackingData
      });
    });

    newsFooterRef.current.querySelector('.js-list-companies').querySelectorAll('a').forEach((link, index) => {
      const trackingData = createTrackingData(companyItems[index], index);
      trackFn.clickUnit({
        element: link,
        trackingData
      });
    });

    newsFooterRef.current.querySelector('.js-list-brands').querySelectorAll('a').forEach((link, index) => {
      let trackingData;
      if (index === 1) {
        // Buzzfeed brand
        trackingData = createTrackingData(brandItems[index], index);
      } else {
        // BuzzfeedNews logo
        trackingData = createTrackingData(brandItems[index], index);
        trackFn.impressionUnit({
          element: link,
          trackingData
        });
      }

      trackFn.clickUnit({
        element: link,
        trackingData
      });
    });
  }, [newsFooterRef, trackFn]);

  const onCcpaClick = useCallback(() => {
    trackFn.contentAction({
      item_name: 'ccpa_button',
      item_type: 'button',
      unit_name: 'overlay',
      unit_type: 'nav',
      action_type: 'show',
      action_value: 'ccpa_modal'
    });
    window.__uspapi('displayUspUi');
  }, []);

  useEffect(() => {
    api.getUSPData()
      .then(({ uspData }) => {
        //checking if user is in US before loading ccpa
        //californians have the option to opt out from anywhere in the us
        if (uspData.uspString !== '1---') {
          setShowCcpa(true);
        }
      });
  }, []);

  return (
    <div ref={newsFooterRef} className={`${styles.pageFooter} xs-px2 xs-py3 sm-p5 xs-mt3 embed-page-footer`}>
      <footer className={styles.container} id="news-footer" tabIndex={-1}>
        <div className={styles.footerTop}>
          <div className={styles.footerInternal}>
            <h2 className={styles.heading}>Sections</h2>
            <nav aria-label="Sections">
              <ul className={`${styles.list} ${styles.listSections} js-list-sections`}>
                {sectionsItems.map((item, index) => (
                  <li className={styles.listItem} key={index}>
                    <a className={styles.listLink} href={item.link}>{item.text}</a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className={styles.footerExternal}>
            <div className={styles.column}>
              <h2 className={styles.heading}>Company</h2>
              <nav aria-label="Company">
                <ul className={`${styles.list} js-list-companies`}>
                  {companyItems.map((item, index) => (
                    <li className={styles.listItem} key={index}>
                      <a className={styles.listLink} href={item.link}>{item.text}</a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className={styles.column}>
              <h2 className={styles.heading}>Follow Us</h2>
              <nav aria-label="Follow Us">
                <ul className={`${styles.list} ${styles.listFollow} js-list-socials`}>
                  {followItems.map((item, index) => (
                    <li className={styles.listItem} key={index}>
                      <a className={styles.listLink} href={item.link}>{item.icon} {item.text}</a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <div className={styles.ccpa}>
            {showCcpa && (
              <>
                <p>US residents can opt out of &#34;sales&#34; of personal data.</p>
                <button className={styles.button} onClick={onCcpaClick}>Do not sell or share my personal information</button>
              </>
            )}
          </div>
          <ul className={`${styles.list} ${styles.listBrand} js-list-brands`}>
            <li className={styles.listItem}>
              <a className={styles.listLink} href="https://buzzfeednews.com"><BuzzfeedNewsLogo className={styles.buzzfeedNewsLogo}/></a>
            </li>
            <li className={styles.listItem}>
              <a className={styles.listLink} href="https://www.buzzfeed.com">a <BuzzfeedLogo className={styles.buzzfeedLogo}/> brand.</a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default NewsPageFooter;
