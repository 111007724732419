import React, { useCallback, useEffect, useState, useRef } from 'react';
import stickyRegistry from '@buzzfeed/bf-utils/lib/sticky-registry';
import styles from './desktop-sticky-ad.module.scss';
import AdUnit from '../../AdUnit';
import { useBreakpoint } from '@buzzfeed/react-components';

function DesktopStickyAd({ buzz }) {
  const containerRef = useRef(null);
  // minHeight used on placeholder to prevent page shift after ad is sticky
  const [minHeight, setMinHeight] = useState(122)
  const [mounted, setMounted] = useState(false);
  const [topAdjustment, setTopAdjustment] = useState(64);
  const [isAdRendered, setIsAdRendered] = useState(false);
  const [adFinished, setAdFinished] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const isLg = useBreakpoint('(min-width: 64rem)');
  const minHeightStyle = isSticky ? {minHeight: `${minHeight}px`} : {};
  // Since the server render will will always be on desktop height,
  // need to wait until mount for reliable breakpoint.
  const displayAd = mounted && isLg;

  useEffect(() => {
    setMounted(true)
  }, []);

  const bindOnAdRender = () => {
    if (isAdRendered) {
      return;
    }
    setIsAdRendered(true);
  };

  const onScroll = useCallback(() => {
    if (isAdRendered && !adFinished) {
      setIsSticky(true);
    }
    if (window.scrollY < 162) {
      setIsSticky(false);
    }
  }, [isAdRendered, adFinished]);

  useEffect(() => {
    if (containerRef.current && isAdRendered) {
      let { height } = containerRef.current.getBoundingClientRect();
      if (height !== minHeight) {
        setMinHeight(height);
      }
    }
  }, [isAdRendered, minHeight]);

  useEffect(() => {
    if (adFinished) {
      return;
    }
    if (isAdRendered) {
      setTimeout(() => {
        setAdFinished(true);
        setIsSticky(false);
        window.removeEventListener('scroll', onScroll);
      }, 5000);
    }
  }, [isAdRendered, isSticky, onScroll, adFinished]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const stickyHandler = useCallback(() => {
    const top = stickyRegistry.getAvailableTop(containerRef.current) + 4;
    if (topAdjustment !== top) {
        setTopAdjustment(top);
    }
  }, [containerRef, topAdjustment]);

  useEffect(() => {
    stickyHandler();
    stickyRegistry.subscribe(stickyHandler);
    return () => stickyRegistry.unsubscribe(stickyHandler);
  }, [stickyHandler]);

  return (
      <>
        <div ref={containerRef}
          className={`${styles.desktopStickyAdWrapper} ${isSticky && !adFinished ? styles.sticky : ''}`}
          style={{ top: `${topAdjustment}px`, minHeight: `${minHeight}px` }}
        >
        { displayAd &&
          <AdUnit
            onAdRender={bindOnAdRender}
            type="awareness"
            pixiedust={{
              unit_type: 'buzz_head',
              unit_name: buzz.id,
              position_in_unit: 0,
            }}
          />
        }
        </div>
        {/* Placeholder prevents page jump after the sticky ad fades out */}
        <div style={minHeightStyle} className={`sticky-ad-placeholder ${isSticky ? styles.placeholderEnabled : ''}`}></div>
      </>
  )
}

export default DesktopStickyAd;
