import { useEffect } from 'react';
import { CLUSTER } from '../../constants';
import { contentLayoutStability } from '@buzzfeed/cls';

/**
 * Function used to get required properties on the matched DOM element(s). As of now, all supported
 * subbuzzes have a data attribute, `data-cls-content-id`, configured in SRA.
 * @param {HTMLElement} node
 * @returns {Object} - Object containing `content_id` and `content_type` properties.
 * @todo
 * This convention for defining the selector and obtaining the content id and name are now
 * standardized for any element tracked. This will be expected by the library and done there
 * automatically. Once it is, refactor this hook removing these options.
 */
const getProps = node => ({
  content_id: node.closest('[data-cls-content-id]').dataset.clsContentId,
  content_type: 'subbuzz',
});

const options = {
  debug: CLUSTER !== 'prod',
  content: [
    {
      selector: '[data-cls-target]',
      getProps,
    },
  ],
};

/**
 * Custom hook to contain all CLS specific logic.
 * @param  {Number} options.sample_rate - This allows setting a larger sampling rate in the event
 * that CLS data is missing in subbuzz data
 */
export function useContentLayoutStability({ sample_rate, enabled = true } = {}) {
  useEffect(() => {
    const cls = enabled ? contentLayoutStability({ ...options, sample_rate }) : null;
    return () => {
      if (cls) {
        cls.flush();
      }
    };
  }, []);
}
