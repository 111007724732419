import { useMemo } from 'react';
import {
  trackClientContentAction,
  attachClientInternalLinkHandler,
  attachClientExternalLinkHandler,
  attachClientImpressionHandler,
} from './client-event-tracking';

/**
 * Returns a set of functions for tracking events
 *
 * Import at the top of the component/file as:
 * const trackingFn = useNewsFooterTracking(buzz)
 *
 * @returns {Function}
 */
export function useNewsFooterTracking(buzz) {
  const commonData = {
    unit_name: 'section',
    unit_type: 'bottom',
    item_type: 'text',
    subunit_type: 'component',
  };
  return useMemo(
    () => ({
      impressionUnit: ({ element, trackingData }) => {
        if (!buzz || !buzz.id || !element || !trackingData) {
          return;
        }
        attachClientImpressionHandler(
          element,
          buzz,
          {
            ...commonData,
            ...trackingData,
          }
        );
      },
      clickUnit: ({ element, trackingData }) => {
        if (!buzz || !buzz.id || !element || !trackingData) {
          return;
        }
        if (trackingData.target_content_url) {
          attachClientExternalLinkHandler(
            element,
            buzz,
            {
            ...commonData,
            ...trackingData,
          });
        } else {
          attachClientInternalLinkHandler(
            element,
            buzz,
            {
              ...commonData,
              ...trackingData,
            });
        }
      },
      contentAction: trackingData => {
        if (!buzz || !buzz.id || !trackingData) {
          return;
        }
        trackClientContentAction(buzz, trackingData);
      },
    }),
    [buzz]
  );
}
