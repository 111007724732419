import { FacebookIcon } from '../components/Svgs/FacebookIcon';
import { TwitterIcon } from '../components/Svgs/TwitterIcon';
import { InstagramIcon } from '../components/Svgs/InstagramIcon';
export const sectionsItems = [
  {
    text: 'Arts & Entertainment',
    link: '/section/arts-entertainment',
    item_name: 'arts&entertainment',
    subunit_name: 'sections',
    positionInUnit: 0,
  },
  {
    text: 'Books',
    link: '/section/books',
    item_name: 'books',
    subunit_name: 'sections',
    positionInUnit: 0,
  },
  {
    text: 'Celebrity',
    link: '/section/celebrity',
    item_name: 'celebrity',
    subunit_name: 'sections',
    positionInUnit: 0,
  },
  {
    text: 'Culture & Criticism',
    link: '/section/culture',
    item_name: 'culture&criticism',
    subunit_name: 'sections',
    positionInUnit: 0,
  },
  {
    text: 'Health',
    link: '/section/health',
    item_name: 'health',
    subunit_name: 'sections',
    positionInUnit: 0,
  },
  {
    text: 'Internet Culture',
    link: '/section/internet-culture',
    item_name: 'internetculture',
    subunit_name: 'sections',
    position_in_unit: 0,
  },
  {
    text: 'Investigations',
    link: '/investigations',
    item_name: 'investigations',
    subunit_name: 'sections',
    position_in_unit: 0,
  },
  {
    text: 'JPG',
    link: '/section/jpg',
    item_name: 'jpg',
    subunit_name: 'sections',
    position_in_unit: 0,
  },
  {
    text: 'LGBTQ',
    link: '/section/lgbtq',
    item_name: 'lgbtq',
    subunit_name: 'sections',
    position_in_unit: 0,
  },
  {
    text: 'Opinion',
    link: '/collection/opinion',
    item_name: 'opinion',
    subunit_name: 'sections',
    position_in_unit: 0,
  },
  {
    text: 'Politics',
    link: '/section/politics',
    item_name: 'politics',
    subunit_name: 'sections',
    position_in_unit: 0,
  },
  {
    text: 'Tech',
    link: '/section/tech',
    item_name: 'tech',
    subunitName: 'sections',
    position_in_unit: 0,
  }
];

export const companyItems = [
  {
    text: 'Support Us',
    link: 'https://support.buzzfeednews.com/?utm_source=O%26O&utm_medium=footer',
    item_name: 'support_us',
    subunit_name: 'company',
    position_in_unit: 2,
    target_content_url: 'https://support.buzzfeednews.com/?utm_source=O%26O&utm_medium=footer',
  },
  {
    text: 'BuzzFeed.com',
    link: 'https://www.buzzfeed.com',
    item_name: 'buzzFeed.com',
    subunit_name: 'company',
    position_in_unit: 2,
    target_content_url: 'https://www.buzzfeed.com',

  },
  {
    text: 'Privacy Policy',
    link: 'https://www.buzzfeed.com/about/privacy',
    item_name: 'privacy_policy',
    subunit_name: 'company',
    position_in_unit: 2,
    target_content_url: 'https://www.buzzfeed.com/about/privacy',

  },
  {
    text: 'User Agreement',
    link: 'https://www.buzzfeed.com/about/useragreement',
    item_name: 'user_agreement',
    subunit_name: 'company',
    position_in_unit: 2,
    target_content_url: 'https://www.buzzfeed.com/about/useragreement',

  },
];

export const followItems = [
  {
    text: 'Facebook',
    link: 'https://www.facebook.com/BuzzFeedNews',
    item_name: 'facebook',
    subunit_name: 'connect_with_us',
    position_in_unit: 1,
    target_content_url: 'https://www.facebook.com/BuzzFeedNews',
    icon: <FacebookIcon title="Facebook" />,
  },
  {
    text: 'Twitter',
    link: 'https://twitter.com/BuzzFeedNews',
    item_name: 'twitter',
    subunit_name: 'connect_with_us',
    position_in_unit: 1,
    target_content_url: 'https://twitter.com/BuzzFeedNews',
    icon: <TwitterIcon title="Twitter" />,
  },
  {
    text: 'Instagram',
    link: 'https://www.instagram.com/buzzfeednews',
    item_name: 'instagram',
    subunit_name: 'connect_with_us',
    position_in_unit: 1,
    target_content_url: 'https://www.instagram.com/buzzfeednews',
    icon: <InstagramIcon title="Instagram" />,
  },
];

export const brandItems = [
  {
    text: 'BuzzFeed News Home',
    link: 'https://buzzfeednews.com',
    item_name: 'buzzfeednews_logo',
    subunit_name: 'brand_info',
    position_in_unit: 3,
  },
  {
    text: 'BuzzFeed',
    link: 'https://www.buzzfeed.com',
    item_name: 'buzzfeed_logo',
    subunit_name: 'brand_info',
    position_in_unit: 3,
    target_content_url: 'https://www.buzzfeed.com',
  }
];
