import React, { useEffect, useContext } from 'react';
import BuzzContext from '../../contexts/buzz';
import { pressboard_usernames } from '../../constants';

const inline_js = `!function(p,r,e,s,b,d){p.pbq||(s=p.pbq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},s.version='1.0',s.queue=[],b=r.createElement(e),b.async=!0,b.src='https://sr.studiostack.com/v3/services',d=r.getElementsByTagName(e)[0],d.parentNode.insertBefore(b,d))}(window,document,'script');pbq('init', '250740');pbq('set', 'story');`;

const PressBoardAnalytics = () => {
  const buzz = useContext(BuzzContext);
  const shouldHavePressBoard = pressboard_usernames.includes(buzz.username);

  // Append inline js
  useEffect(
    () => {
      if (shouldHavePressBoard) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = inline_js;
        document.head.appendChild(script);
      }
    },
    [buzz.username] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!shouldHavePressBoard) {
    return null;
  }

  return <div id="pressboard-ad-sponsorship-msg" />;
};

export default PressBoardAnalytics;
