import defaultStyles from './styles/default.module.scss';
import amazonpetsStyles from './styles/amazonpets.module.scss';

const promotions = {
  'newsletter-popup': {
    title: 'Need A Shopping Buddy?',
    subtitle:
      'Get off the product struggle bus, and get recs on stuff that actually works—straight to your inbox!',
    category: 'giftguide',
    styles: defaultStyles,
    useShoppingDefaultTamplate: true,
    newsletterId: 'buzzfeed_email_shopping'
  },
  'newsletter-primeday': {
    title: 'Shop to Save, Save to Shop',
    subtitle:
      'Get more Prime Deals in less Prime time with our editor-approved picks—that’ll stretch those dollars—right to your inbox.',
    category: 'shopping',
    styles: defaultStyles,
    useShoppingDefaultTamplate: true,
    newsletterId: 'buzzfeed_email_shopping'
  },
  amazonpets: {
    title: '30-Day New Dog Course',
    subtitle:
      'The best place to start for both new dog parents and those dreaming of getting their own furry friend 💕',
    category: 'amazonpets',
    styles: amazonpetsStyles,
    newsletterId: 'buzzfeed_email_course-dogs'
  },
};

export default promotions;
