import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useBreakpoint } from '@buzzfeed/react-components';
import { useUnitTracking } from '../../../../hooks/analytics/useShoppingUnitTracking';
import BuzzContext from '../../../../contexts/buzz';
import styles from './index.module.scss';
import baggy from '../../../ShoppyBotPromo/images/baggy.svg';
import snowflake from '../../../ShoppyBotPromo/images/snowflake.svg';
import arrow from '../../../ShoppyBotPromo/images/arrow.svg';
import santa from '../../../ShoppyBotPromo/images/santa.png';
import snowflakes from '../../../ShoppyBotPromo/images/snowflakes-small.png';

export function PromoShoppyBotBanner () {
  const buzz = useContext(BuzzContext);
  const isMobile = useBreakpoint('(max-width: 600px)');
  const [prompt, setPrompt] = useState(
    'Try “What are the most popular deals?"'
  );

  const bannerHeader = "Need a shopping buddy?";
  const bannerText = "Shoppy AI has got you covered, find all the gifts for your loved ones this holiday with our shopping bot.";

  const cardTracking = useMemo(
    () => ({
      unit_type: 'buzz_bottom',
      unit_name: buzz.id,
      subunit_type: 'component',
      subunit_name: 'shoppy_bot_banner',
      item_type: 'card',
      item_name: 'shoppy_bot_banner',
      position_in_unit: 0,
      position_in_subunit: 0,
      target_content_type: 'feed',
      target_content_id: '6',
    }),
    []
  );

  const clickLinkRef = useUnitTracking(cardTracking);

  useEffect(() => {
    const promptMessages = [
      'Try “What should I buy my BFF this year?”',
      'Try “What are cheap stocking stuffers?”',
      'Try “What are some really unique gifts?”',
    ];

    const interval = setInterval(() => {
      setPrompt(
        promptMessages[Math.floor(Math.random() * promptMessages.length)]
      );
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <a
      className={ styles.link }
      href="/giftguide"
      target="_blank"
      ref={ clickLinkRef }
    >
      <img className={ styles.background } src={ snowflakes } alt="" />
      <div className={ styles.contentWrapper }>
        { isMobile && (
          <div className={ styles.textWrapper }>
            <b>{ bannerHeader }</b>
            <span>
              { bannerText }
            </span>
          </div>
        ) }

        <div className={ styles.messageWrapper }>
          <div className={ styles.baggy }>
            <img className={ styles.character } src={ baggy } alt="" />
            <img className={ styles.hat } src={ santa } alt="" />
          </div>
          <div className={ styles.message }>
            { isMobile
              ? 'Hi I’m your trusty gift guide, Shoppy.'
              : 'Hi! I’m your trusty gift guide, Shoppy. How can I help you?' }
          </div>
          { isMobile && (
            <div className={ styles.message }>
              How can I help you?
            </div>
          ) }
          { !isMobile && (
            <div className={ styles.input }>
              <span>{ prompt }</span>
              <img src={ arrow } alt="" />
            </div>
          ) }
        </div>
        { !isMobile && (
          <div className={ styles.textWrapper }>
            <b>{ bannerHeader }</b>
            <span>
              { bannerText }
            </span>
          </div>
        ) }
        { isMobile && (
          <div className={ styles.input }>
            <span>{ prompt }</span>
            <img src={ arrow } alt="" />
          </div>
        ) }
      </div>
      <div className={ styles.banner }>
        <ul className={ styles.marquee }>
          { [...Array(10)].map((x, i) => (
            <React.Fragment key={ i }>
              <li>
                <span>HAPPY HOLIDAYS</span>
              </li>
              <li>
                <img src={ snowflake } alt="" />
              </li>
            </React.Fragment>
          )) }
        </ul>

        <ul className={ styles.marquee }>
          { [...Array(10)].map((x, i) => (
            <React.Fragment key={ i }>
              <li>
                <span>HAPPY HOLIDAYS</span>
              </li>
              <li>
                <img src={ snowflake } alt="" />
              </li>
            </React.Fragment>
          )) }
        </ul>
      </div>
    </a>
  );
}

export default PromoShoppyBotBanner;